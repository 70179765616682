"use client";

import React, { useState, useEffect } from "react";
import { LanguageSelector as LanguageSelectorBrc } from "@britishredcross/component-library/src/components/molecules/language-selector/language-selector";
import { type Language } from "@britishredcross/kontent-integration/dist/esm/services/localisation-service";
import { DEFAULT_LOCALE } from "@britishredcross/kontent-integration/dist/esm/constants/localisation";
import { getLocaleInUrlPath } from "@/utils/get-locale-in-url-path";

interface LanguageSelectorProps {
	languages: Language[] | [];
	languageSelectorLabel: string;
}

export function LanguageSelector({
	languages,
	languageSelectorLabel,
}: LanguageSelectorProps): JSX.Element | null {
	const [localeInPath, setLocaleInPath] = useState<string | undefined>();

	useEffect(() => {
		setLocaleInPath(
			getLocaleInUrlPath(window.location.pathname) ?? DEFAULT_LOCALE
		);
	}, []);

	if (languages.length < 2) return null;

	const handleLanguageChange = (languageCode: string): void => {
		const { pathname } = window.location;
		let newPath;
		const newLocale = getLocaleInUrlPath(pathname);
		if (newLocale) {
			newPath = pathname.replace(
				new RegExp(`^/${newLocale}`),
				`/${languageCode}`
			);
		} else {
			newPath = `/${languageCode}${pathname}`;
		}
		window.location.href = newPath;
	};

	return localeInPath ? (
		<LanguageSelectorBrc
			languages={languages}
			defaultLanguage={localeInPath ?? DEFAULT_LOCALE}
			onLanguageChange={handleLanguageChange}
			languageSelectorLabel={languageSelectorLabel}
		/>
	) : null;
}
