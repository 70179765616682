"use client";
import React, { useState } from "react";
import { Button } from "../../atoms/button/button";
import { ButtonStyle } from "../../atoms/button/button.types";
import { type LanguageSelectorProps } from "./language-selector.types";

export function LanguageSelector({
	languages,
	defaultLanguage = "",
	onLanguageChange,
	languageSelectorLabel,
}: LanguageSelectorProps): JSX.Element {
	const [selectedLanguage, setSelectedLanguage] =
		useState<string>(defaultLanguage);

	const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedLanguage(event.target.value);
	};

	// Handle form submission
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault(); // Prevent page reload
		onLanguageChange(selectedLanguage); // Send the selected language on submit
	};

	return (
		<section className="w-full max-content mx-auto bg-white p-4 lg:px-0">
			<form onSubmit={handleSubmit} className="block md:flex justify-end">
				<div className="w-full md:w-1/3 lg:w-1/4">
					<label htmlFor="language-select" className="block text-sm">
						{languageSelectorLabel}
					</label>
					<select
						id="language-select"
						className="brc-form-select appearance-none bg-no-repeat w-full rounded-none focus:border-1 focus:border-green-secondary focus:brc-shadow-fieldfocus focus:outline-0 h-12 p-y2.5 px-4 text-sm text-center border border-solid border-black-primary md:min-w-max md:text-left"
						value={selectedLanguage}
						onChange={handleChange}
						dir="ltr"
					>
						{languages.map((lang) => (
							<option key={lang.codename} value={lang.codename}>
								{lang.name}
							</option>
						))}
					</select>
				</div>

				<div className="pt-4 pl-0 md:pt-6 w-full md:pl-4 md:w-auto">
					<Button buttonStyle={ButtonStyle.Primary} buttonType="submit">
						Submit
					</Button>
				</div>
			</form>
		</section>
	);
}
