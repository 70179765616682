"use client";
import { useEffect } from "react";
import KontentSmartLink from "@kontent-ai/smart-link";
import WebSpotlight from "@/utils/web-spotlight";
import AppConfig from "@/utils/app-config";

export default function KontentAISmartLinkInit() {
	const setKontentBodyAttributes = (): void => {
		[
			{
				attr: WebSpotlight.dataKontentProjectId,
				value: process.env.NEXT_PUBLIC_KONTENT_ENVIRONMENT_ID ?? "",
			},
			{
				attr: WebSpotlight.dataKontentLanguageCodename,
				value: AppConfig.locale,
			},
		].forEach(({ attr, value }) => {
			const attrValue = document.body.getAttribute(attr);
			if (!attrValue) document.body.setAttribute(attr, value);
		});
	};

	useEffect(() => {
		setKontentBodyAttributes();
		const kontentSmartLink = KontentSmartLink.initialize();
		return () => {
			kontentSmartLink.destroy();
		};
	}, []);

	return null;
}
